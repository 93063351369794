import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'
import PageWrapper from '../components/pageWrapper'
import { DonationLink } from '../components/donationButton'
import { renderRichText } from '../lib/richTextRender'

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulPost')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout>
        <PageWrapper>
          <Helmet title={`${post.title} | ${siteTitle}`} />
          <div className="wrapper p-3">
            <div className="d-flex align-items-end mb-4">
              <div>
                <p className="mb-2 text-muted">{post.createdAt}</p>
                <p className="mb-2 text-muted font-weight-bold">
                  {post.subtitle}
                </p>
                <h2 className="section-headline font-weight-bold">
                  {post.title}
                </h2>
                <p className="font-weight-bold">{post.intro}</p>
              </div>
              <div className="vl"></div>
              <DonationLink />
            </div>

            {post.videoLink && (
              <iframe
                width="100%"
                height="720"
                src={post.videoLink}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                Adding
                allowfullscreen="allowfullscreen"
              ></iframe>
            )}
            <div>{renderRichText(post.desc.json)}</div>

            <p className="mb-2 text-muted">{post.contactMeViaMail}</p>
            <p className="mb-2 text-muted font-weight-bold">{post.donations}</p>
          </div>
        </PageWrapper>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPost(slug: { eq: $slug }) {
      title
      intro
      videoLink
      donations
      contactMeViaMail
      subtitle
      createdAt(formatString: "DD | MM | YYYY")
      desc {
        json
      }
    }
  }
`

import React from 'react'
import { Link } from 'gatsby'

const DonationButton = () => (
  <form
    action="https://www.paypal.com/cgi-bin/webscr"
    method="post"
    target="_top"
    className="mb-0 d-flex align-items-end"
  >
    <input type="hidden" name="cmd" value="_donations" />
    <input type="hidden" name="business" value="annika.glaeser@gmail.com" />
    <input type="hidden" name="item_name" value="Guckmalyoga" />
    <input type="hidden" name="currency_code" value="EUR" />
    <button className="donate-button" type="submit">
      Direkt zu PayPal
    </button>

    <img
      alt=""
      border="0"
      src="https://www.paypal.com/de_DE/i/scr/pixel.gif"
      width="1"
      height="1"
    />
  </form>
)
export const DonationLink = () => (
  <Link to="spenden">
    <button className="donate-button">Ich will spenden!</button>
  </Link>
)
export default DonationButton
